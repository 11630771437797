<template>
  <div id="app" class="app">
    <transition name="fade">
      <loader
          v-if=" loaded || $route.name==='process' && !process && !products || $route.name==='all-recipe' && !recipes || $route.name==='receipt' && !oneRecipe"/>
    </transition>
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header/>
          <transition name="fade" mode="out-in">
            <router-view/>
          </transition>
          <main-footer class="fullPageSection"/>
        </div>
      </div>
    </div>
    <hidden/>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import {isMobile, isIPad} from '@/helpers/variables/index'

export default {
  name: 'app',
  data() {
    return {
      loaded: true,
    }
  },
  components: {
    Hidden,
    MainHeader,
    MainFooter
  },
  watch: {},
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      process: 'setting/productProcess',
      products: 'setting/process',
      recipes: 'receipt/recipes',
      content: 'home/homePage',
      oneRecipe: 'receipt/oneRecipe',
      contacts: 'setting/contacts'

    })
  },
  created() {

  },
  mounted() {
    this.loaded = true
    setTimeout(() => {
      this.loaded = false
    }, 2000)
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  }
}
</script>

