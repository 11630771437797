//home
export const GET_HOME_PAGE = 'GET_HOME_PAGE'

//setting
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CONTACTS = 'GET_CONTACTS'
export const SEND_FEEDBACK='SEND_FEEDBACK'
export const GET_PRODUCT_PROCESS='GET_PRODUCT_PROCESS'
export const GET_PROCESS='GET_PROCESS'

//receipt
export const GET_ALL_RECEIPT = 'GET_ALL_RECEIPT'
export const GET_ONE_RECEIPT='GET_ONE_RECEIPT'

