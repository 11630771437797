import {$http} from '@/utils/https'
import {
  SET_ALL_RECEIPT, SET_ONE_RECEIPT
} from '../mutation-types';
import {
  GET_ALL_RECEIPT, GET_ONE_RECEIPT,

} from '../action-types';


const state = {
  recipes: '',
  oneRecipe: ''
};

const getters = {
  recipes: state => state.recipes,
  oneRecipe: state => state.oneRecipe
};

const actions = {
  [GET_ALL_RECEIPT]: async ({commit}) => {

    try {
      const response = await $http.get(`v1/recipes`);

      commit(SET_ALL_RECEIPT, response.data.data);
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_ONE_RECEIPT]: async ({commit}, payload) => {

    try {
      const response = await $http.get(`v1/recipes/${payload.slug}`);

      commit(SET_ONE_RECEIPT, response.data.data);
    } catch (e) {
      throw e;
    } finally {

    }
  },
};

const mutations = {
  [SET_ALL_RECEIPT](state, data) {
    state.recipes = data;
  },
  [SET_ONE_RECEIPT](state, data) {
    state.oneRecipe = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
