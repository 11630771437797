<template>
  <div class="popup">
    <div class="popup-wrap">
      <div class="popup-bg" @click="closePopup()"></div>
      <div class="popup-container" :style="{ 'max-width': maxWidth + 'px' }"
           :class="{'backgroundStyle':customBackground}">
        <div class="wrapper-title" v-if="customStyle" v-html="title"></div>
        <div class="popup-top">
          <div class="popup-top__title" v-html="title" v-if="!customStyle">
            <!--            {{ title }}-->
          </div>
          <button class="popup-close" @click="closePopup()">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.3827 6.76953L23.2311 7.61793L7.61793 23.2299L6.76953 22.3821L22.3827 6.76953Z"
                    fill="#4F4F4F"/>
              <path d="M7.61793 6.76953L23.2311 22.3815L22.3827 23.2305L6.76953 7.61853L7.61793 6.76953Z"
                    fill="#4F4F4F"/>
            </svg>
          </button>
        </div>
        <div class="popup-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'popup-wrap',
  props: {
    title: {},
    maxWidth: {
      type: Number,
      default: 840
    },
    customStyle: {
      type: Boolean,
      default: false
    },
    customBackground: {
      type: Boolean,
      default: false
    }
  },
  created() {
    document.getElementById('html').classList.add('hide')
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
      document.getElementById('html').classList.remove('hide')
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";

.backgroundStyle {
  background-color: #2b2223 !important;

  .popup-content {
    overflow-y: auto;
  }

  .popup-close svg {
    path {
      fill: white;
    }

    &:hover {
      path {
        fill: gray;
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  //overflow-y: auto;

  &-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, .5);
  }

  &-wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    overflow-y: auto;
  }

  &-container {
    top: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 840px;
    background: #fff;
    padding: 25px 32px;
    position: absolute;
    z-index: 999;
    height: auto;
    left: 50%;


    .wrapper-title {
      font-weight: 300;
      font-size: 24px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 65px;
      background-color: #2B2223;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &-content {
    padding: 5px;
    min-height: 40px;
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
      width: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #2d3340;
      width: 2px;
      border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      width: 2px;
    }
  }

  &-top {
    width: 100%;
    padding-bottom: 15px;
    position: relative;
    margin-top: -15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__title {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 35px;
      font-weight: 300;
      font-size: 24px;
      //line-height: 17px;
      color: white;

    }
  }

  &-close {
    position: absolute;
    right: -20px;
    top: -5px;
    padding: 0;
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
      transition: ease-in-out .3s;
      //color: #9a9a9a;
    }

    &:hover {

      svg {
        path {
          transition: ease-in-out .3s;
          fill: white;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  //.popup-container {
  //  max-width: 375px !important;
  //}
  .popup-bg {
    position: fixed;
  }
  .popup-wrap {
    height: 100vh;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  .popup-wrap {
    overflow-y: auto;
  }
}

@media screen and (max-width: 500px) {
  .popup-close {
    position: absolute;
    right: -10px;
    top: -5px;
    padding: 0;
    width: 30px;
    height: 30px;
  }
  .popup-top {
    width: 100%;
    padding-bottom: 15px;
    position: relative;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .popup-container {
    max-width: 350px !important;
  }
  .popup-container {
    padding: 15px 20px;
  }
  .popup-top__title {
    font-size: 16px;
  }
  .popup-top {
    padding-bottom: 10px;
  }
  .popup-content {
    max-height: 300px;
    min-height: 120px;
  }
  .popup-container .wrapper-title {
    font-weight: 300;
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 65px;
    background-color: #2B2223;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>

