//home
export const SET_HOME_PAGE = 'SET_HOME_PAGE'
export const HOME_PAGE_LOADING = 'HOME_PAGE_LOADING'

//settings
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_CONTACTS = 'SET_CONTACTS'
export const SHOW_THANKS_POPUP = 'SHOW_THANKS_POPUP'
export const SET_PRODUCT_PROCESS='SET_PRODUCT_PROCESS'
export const SET_PROCESS='SET_PROCESS'
export const SHOW_MORE_POPUP='SHOW_MORE_POPUP'
export const SHOW_MORE_DATA='SHOW_MORE_DATA'
//receipt
export const SET_ALL_RECEIPT = 'SET_ALL_RECEIPT'
export const SET_ONE_RECEIPT = 'SET_ONE_RECEIPT'

