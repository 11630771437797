import {mapActions, mapGetters, mapMutations} from "vuex";
import Vue from 'vue'

export default {
    name: "main-header",
    data() {
        return {
            openSidebar: false
        }
    },
    created() {

        let hash = this.$route.hash.replace(/[^a-zа-яё]/gi, '')

        if (hash) {
            setTimeout(() => {
                this.smoothScroll(hash)
                location.hash.replace('#', '')
            }, 300)


        }
    },
    mounted() {
    },
    watch: {
        'location'() {
            location.hash = ''
        }

    },
    methods: {
        ...mapMutations({}),
        hideActiveLink() {
            let activeItem = document.querySelector('.menuItem')
            activeItem.classList.remove('homeItem')
        },
        addActiveLink() {
            let activeItem = document.querySelector('.menuItem')
            activeItem.classList.add('homeItem')
        },
        changeLanguage(code) {
            this.$setLanguage(code).then(data => {
                window.location.reload();
            });
        },
        smoothScroll(item) {
            let link = document.getElementById(item)

            if (link === null) {

                this.$router.push({name: 'home'}).then(() => {
                    setTimeout(() => {
                        const myEl = document.getElementById(item)
                        this.$smoothScroll({
                            scrollTo: myEl,
                            // hash: '#sampleHash'
                        })
                    }, 2000)
                }).catch(() => {
                   
                    setTimeout(() => {
                        this.openSidebar = false
                    }, 400)
                    setTimeout(() => {
                        const myEl = document.getElementById(item)
                        this.$smoothScroll({
                            scrollTo: myEl,
                            // hash: '#sampleHash'
                        })
                        console.log()
                    }, 1000)

                })
            } else {

                const myEl = document.getElementById(item)
                this.$smoothScroll({
                    scrollTo: myEl,
                    // hash: '#sampleHash'
                })
                this.openSidebar = false
            }


        },

        openMenu() {
            this.openSidebar = !this.openSidebar
        },
    },
    computed: {
        ...mapGetters([
            'languages',
            'currentLanguage',

        ]),
    }
}
