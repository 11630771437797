import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation'

import {
  SET_CONTACTS,
  SET_LANGUAGE_LIST,
  SET_TRANSLATIONS,
  SHOW_THANKS_POPUP,
  SET_PRODUCT_PROCESS, SET_PROCESS, SHOW_MORE_POPUP, SHOW_MORE_DATA
} from '../mutation-types';

import {
  GET_CONTACTS,
  GET_LANGUAGE_LIST,
  GET_TRANSLATIONS, SEND_FEEDBACK,
  GET_PRODUCT_PROCESS, GET_PROCESS,
} from '../action-types';

const state = {
  locales: null,
  translations: null,
  contacts: '',
  showPopup: false,
  productProcess: '',
  process: '',
  showMore: '',
  showMoreData: ''
};

const getters = {
  locales: state => state.locales,
  translations: state => state.translations,
  contacts: state => state.contacts,
  showPopup: state => state.showPopup,
  productProcess: state => state.productProcess,
  process: state => state.process,
  showMore: state => state.showMore,
  showMoreData: state => state.showMoreData
};

const actions = {
  [GET_PROCESS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/products');
      commit(SET_PROCESS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_PRODUCT_PROCESS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/page/production-process');
      commit(SET_PRODUCT_PROCESS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [SEND_FEEDBACK]: async ({commit}, payload) => {
    try {
      const response = await $http.post('v1/feedback', payload);
      // commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/contacts');
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_LANGUAGE_LIST]: async ({commit}) => {
    try {
      const response = await $http.get('v1/faq');
      commit(SET_LANGUAGE_LIST, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_TRANSLATIONS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/translations');
      let result = Object.assign(response.data, translation);
      commit(SET_TRANSLATIONS, result);
      return result;
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  [SET_LANGUAGE_LIST](state, status) {
    state.locales = status;
  },
  [SET_TRANSLATIONS](state, status) {
    state.translations = status;
  },
  [SET_CONTACTS](state, status) {
    state.contacts = status;
  },
  [SHOW_THANKS_POPUP](state, status) {
    state.showPopup = status
  },
  [SET_PRODUCT_PROCESS](state, data) {
    state.productProcess = data
  },
  [SET_PROCESS](state, data) {
    state.process = data
  },
  [SHOW_MORE_POPUP](state, status, data) {
    state.showMore = status
    state.showMoreData = data
  },
  [SHOW_MORE_DATA](state, data) {
    state.showMoreData = data
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
