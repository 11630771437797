import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "thanks-popup",
  components: {},
  data() {
    return {}
  },
  created() {

  },
  computed: {
    ...mapGetters({})
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({})
  }
}
