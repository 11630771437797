import Vue from 'vue';
import Loader from '@/components/misc/loader.vue';
import PopupWrap from '@/components/misc/popup-wrap.vue';
import mainButton from '@/components/main-button/index.vue';
import smallLoader from '@/components/misc/small-loader.vue';
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueCarousel from 'vue-carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vuelidate from 'vuelidate'




Vue.use(Vuelidate)
Vue.use(VueCarousel);
Vue.component('small-loader', smallLoader);
Vue.component('loader', Loader);
Vue.component('popup-wrap', PopupWrap);
Vue.component('main-button', mainButton);
Vue.component('v-pagination', window['vue-plain-pagination']);
import 'aos/dist/aos.css';

AOS.init({
  disable: function () {
    const maxWidth = 500;
    return window.innerWidth <= maxWidth;
  }
});

Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
})
