import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "show-more",
  components: {},
  data() {
    return {}
  },
  created() {

  },
  computed: {
    ...mapGetters({
      showMorePopup: 'setting/showMoreData'
    })
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({})
  }
}
