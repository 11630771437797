import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

Vue.use(VueRouter);

// const isAuthenticated = (to, from, next) => {
//   if (store.state.auth.isAuthenticated) {
//     next();
//     return
//   }
//   next('/sing-in')
// };

const routes = [
  {
    path: '/:lang?',
    name: 'home',
    component: () => import('@/modules/home/index.vue')
  },
  {
    path: '/:lang?/production',
    name: 'process',
    component: () => import('@/modules/production/index.vue')
  },
  {
    path: '/:lang?/recipe',
    name: 'all-recipe',
    component: () => import('@/modules/allRecipe/index.vue')
  },
  {
    path: '/:lang?/receipt/:slug',
    name: 'receipt',
    component: () => import('@/modules/one-recipe/index.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
});

export default router
