import {$http} from '@/utils/https'
import {
  SET_HOME_PAGE,
  HOME_PAGE_LOADING
} from '../mutation-types';
import {
  GET_HOME_PAGE,

} from '../action-types';


const state = {
  homePage: '',
  homaPageLoading: false
};

const getters = {
  homePage: state => state.homePage,
  homaPageLoading: state => state.homaPageLoading
};

const actions = {
  [GET_HOME_PAGE]: async ({commit}) => {
    commit(HOME_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/page/home`);

      commit(SET_HOME_PAGE, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(HOME_PAGE_LOADING, false);
    }
  },
};

const mutations = {
  [SET_HOME_PAGE](state, data) {
    state.homePage = data;
  },
  [HOME_PAGE_LOADING](state, status) {
    state.homaPageLoading = status
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
