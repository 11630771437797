import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import { translation } from '@/helpers/translation'


const proxy = {
  getTranslation: async () => {
    const response = await store.dispatch('setting/GET_TRANSLATIONS');
    Vue.use(response, translation);
    return response;
  }
};


Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'ua-UA',
    languages: [
      {
        name: 'Українська',
        title: 'Ua.',
        key: 'uk',
        code: 'ua-UA',
        urlPrefix: 'uk',
        translationKey: 'uk'
      },
      {
        name: 'English',
        title: 'En.',
        key: 'en',
        code: 'en-En',
        urlPrefix: 'en',
        translationKey: 'en',
      },
    ],
    translations: proxy,
  },
});
