//utils
import './utils/plugins'
import './utils/translations'
import './utils/global-components'
//
import moment from 'moment';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
// moment
Vue.prototype.moment = moment


Vue.use(VueHead)
//vue configs
Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});

