import {mapGetters, mapMutations} from 'vuex';
import thanksPopup from "@/components/popups/thanks-popup/index.vue";
import showMore from "@/components/popups/show-more/index.vue";

export default {
  name: 'hidden',
  components: {
    thanksPopup,
    showMore
  },
  computed: {
    ...mapGetters({
      showPopup: 'setting/showPopup',
      showMorePopup: 'setting/showMore',
      showMorePopupData: 'setting/showMoreData'
    })
  },
  methods: {
    ...mapMutations({
      showThanksPopup: 'setting/SHOW_THANKS_POPUP',
      showMoreItem: 'setting/SHOW_MORE_POPUP'
    })
  }
}
